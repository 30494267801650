
import React, { PureComponent, Fragment } from 'react';
import $ from "jquery";
import { NavLink } from "react-router-dom";
import { AJAX_REQUEST, ENABLE_NEW_LOGIN, MEAL_MENU_PUBLIC, GET_STORAGE } from "../../../Constants/AppConstants";
import Parser from 'html-react-parser';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LikedProImg1 from './../../../Assets/images/refer_images/like_product_1.svg'
import LikedProImg2 from './../../../Assets/images/refer_images/like_product_2.svg'
import LikedProImg3 from './../../../Assets/images/refer_images/like_product_3.svg'
import LikedProImg4 from './../../../Assets/images/refer_images/like_product_4.svg'
import CopyIcon from './../../../Assets/images/customer_images/copy_icon.svg'
import FacebookIcon from './../../../Assets/images/customer_images/dashboard_facebook_icon.svg'
import InstagramIcon from './../../../Assets/images/customer_images/dashboard_instagram_icon.svg'
const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1000: {
            items: 4.5,

        }
    },
};
class MyAccount extends PureComponent {
    constructor(props) {
        super(props)

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        this.state = {
            userInfo: [],
            errorMeg: '',
            affiliate_panel_video_embed: '',
            loading: true,
            meal_menu_public: settings && settings.meal_menu_public == "yes" ? true : false
        }
        document.title = "My Account -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.getUserInfo();
    }

    copyAffiliateURL = (e) => {
        var copyText = document.getElementById("affiliateURL");
        copyText.select();
        document.execCommand("copy");
        $("#copyMsg").show();
        $("#copyMsg").hide(1000);
    }

    copyMealAffiliateURL = (e) => {
        var copyText = document.getElementById("mealAffiliateURL");
        copyText.select();
        document.execCommand("copy");
        $("#copyMealMsg").show();
        $("#copyMealMsg").hide(1000);
    }

    getUserInfo = () => {
        AJAX_REQUEST("POST", "distributor/dashboard", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    userInfo: results.response.data,
                    affiliate_panel_video_embed: results.response.data.affiliate_panel_video_embed,
                    loading: false
                });
            } else {
                this.setState({
                    errorMeg: results.response.message,
                    loading: false
                })
            }
        });
    }

    render() {
        // const { user } = this.props.auth;
        const currentDate = new Date();
        const fullMonthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        const formattedSalesText = `${fullMonthNames[currentDate.getMonth()]}`;

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <Fragment>
                            {
                                ENABLE_NEW_LOGIN && this.state.affiliate_panel_video_embed !== '' ?
                                    <div className="d_embeed_video user_login_join_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '25px' }}>
                                        {
                                            (this.state.affiliate_panel_video_embed != null) && (this.state.affiliate_panel_video_embed != '') ?
                                                <div className="d_embeed_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '10px' }}>
                                                    {Parser(this.state.affiliate_panel_video_embed)}
                                                    {/* <div className="wistia_embed wistia_async_rpyx2ikvy4 popover=true popoverAnimateThumbnail=true" style={{display:'inline-block', height:'400px', position:'relative', width:'100%',}}>&nbsp;</div> */}
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }
                            {
                                ENABLE_NEW_LOGIN && this.state.userInfo.affiliate_panel_content !== '' ?
                                    <div className="d_embeed_video user_login_join_video" style={{ overflow: 'hidden', width: '100%', marginBottom: '25px' }}>

                                        {
                                            (this.state.userInfo.affiliate_panel_content != null) && (this.state.userInfo.affiliate_panel_content != '') ?
                                                <Fragment>
                                                    {Parser(this.state.userInfo.affiliate_panel_content)}
                                                </Fragment>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }
                            <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">Dashboard</h2>
                            <h3 className="font_16 mb_15 text_black fw_ar_reg">Affiliate Dashboard &gt; Dashboard</h3>
                            <Row className='dashboard_row aff_dashboard_row'>
                                <Col md={4}>
                                    <div className="dashboar_col">
                                        <h3 className="font_16 text_black fw_el_bold m-0 text-uppercase">Order</h3>
                                        <div className="aff_order_div d-flex flex-column justify-content-start">
                                            <div className="mb_33">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Today's Order</h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold"><NavLink className="font_16  text_black fw_ar_bold" to="/my-account/view-order">{this.state.userInfo.hasOwnProperty('todays_order') ? this.state.userInfo.todays_order : ''}</NavLink></h3>
                                            </div>
                                            <div className="">
                                                <h3 className="font_16 mb_5 text_black fw_ar_bold">Lifetime Success Order </h3>
                                                <h3 className="font_20 m-0 text-black fw_ar_bold"><NavLink className="font_16  text_black fw_ar_bold" to="/my-account/view-order">{this.state.userInfo.hasOwnProperty('lifetime_success_order') ? this.state.userInfo.lifetime_success_order : ''}</NavLink></h3>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    {
                                    this.state.userInfo.Commission_type == 'Default' ?
                                        <div className="dashboar_col  position-relative">
                                            <h3 className="font_16 text_black fw_el_bold mb_27  text-uppercase">Earnings</h3>
                                            <ul className="tier_ul">
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Supplement Commission</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">{this.state.userInfo.default_global_commission}%</span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Protein Commission</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">20%</span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg ">{formattedSalesText} Sales Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg ">
                                                        {this.state.userInfo.hasOwnProperty('Current_Sale') ? this.state.userInfo.Current_Sale : ''}
                                                    </span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg fw_ar_bold">{formattedSalesText}  Commission Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg fw_ar_bold">
                                                        {this.state.userInfo.hasOwnProperty('Current_Sale') ? this.state.userInfo.commission_earned : ''}
                                                    </span>
                                                </li>
                                            </ul>
                                            {/* <div className="tier_pri mt_20">
                                                <div className="d-flex align-items-center justify-content-between mb_8">
                                                    <span className="d-block font_16 fw_ar_reg">No Lock in End Date for Default Commission Commission</span>
                                                </div>
                                            </div> */}
                                        </div>
                                        : 
                                        <div className="dashboar_col">
                                            <h3 className="font_16 text_black fw_el_bold mb_27  text-uppercase">Current Tier</h3>
                                            <ul className="tier_ul">
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Tier</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">{this.state.userInfo.hasOwnProperty('Current_Tier') ? this.state.userInfo.Current_Tier : ''}</span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Current Commission</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">{this.state.userInfo.hasOwnProperty('Tier_Commission') ? this.state.userInfo.Tier_Commission : ''}</span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Lock in End Date</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">{this.state.userInfo.hasOwnProperty('User_Validity') ? this.state.userInfo.User_Validity : ''}</span>
                                                </li>
                                                <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                    <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Current Month Sale</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg">{this.state.userInfo.hasOwnProperty('Current_Sale') ? this.state.userInfo.Current_Sale : ''}</span>
                                                </li>
                                            </ul>
                                            <div className="tier_pri mt_20">
                                                <div className="d-flex align-items-center justify-content-between mb_8">
                                                    <span className="font_11 text_black d-block fw_ar_reg">{this.state.userInfo.hasOwnProperty('Min_Sale') ? this.state.userInfo.Min_Sale : ''}</span>
                                                    <span className="font_11 text_black d-block fw_ar_reg">{this.state.userInfo.hasOwnProperty('Max_Sale') ? this.state.userInfo.Max_Sale : ''}</span>
                                                </div>
                                                <div className="position-relative">
                                                    <div class="progress_show show">
                                                        <ProgressBar className='affi_progress w-100' now={this.state.userInfo.hasOwnProperty('Current_Sale_Value') ? this.state.userInfo.Current_Sale_Value : ''} max={this.state.userInfo.hasOwnProperty('Max_Sale_Value') ? this.state.userInfo.Max_Sale_Value : ''} />
                                                        {/* <p  rogress className='affi_progress w-100' value={this.state.userInfo.hasOwnProperty('Current_Sale_Value') ? this.state.userInfo.Current_Sale_Value : ''} max={this.state.userInfo.hasOwnProperty('Max_Sale_Value') ? this.state.userInfo.Max_Sale_Value : ''}></progress> */}
                                                    </div>
                                                    <div class="hide">
                                                        <span className="progress_hover let_spa_2 text-right d-block font_12 fw_ar_reg">{this.state.userInfo.hasOwnProperty('Current_Sale') ? this.state.userInfo.Current_Sale : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col md={4}>
                                    <div className="dashboar_col">
                                        <h3 className="font_16 text_black fw_el_bold mb_11 text-uppercase">Commission (Since Last Payout)</h3>
                                        <ul className="tier_ul">
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Earned </span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="my-account/commission-payout"> {this.state.userInfo.hasOwnProperty('total_earned') ? this.state.userInfo.total_earned : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Adjustment</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="my-account/commission-payout">{this.state.userInfo.hasOwnProperty('total_adjustment') ? this.state.userInfo.total_adjustment : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Received</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="my-account/payment-received">{this.state.userInfo.hasOwnProperty('total_received') ? this.state.userInfo.total_received : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Zero Out</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="my-account/zero-out-commission">{this.state.userInfo.hasOwnProperty('zero_out') ? this.state.userInfo.zero_out : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between mb_9">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_reg">Total Refund/Chargeback</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_reg"><NavLink className="text_black" to="my-account/commission-payout">{this.state.userInfo.hasOwnProperty('total_cancel_refund') ? this.state.userInfo.total_cancel_refund : ''}</NavLink></span>
                                            </li>
                                            <li className="d-flex align-items-center flex-wrap justify-content-between">
                                                <span className="total_span let_spa_2 d-block font_16 fw_ar_bold">Current Balance</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_bold"><NavLink className="text_black" to="my-account/commission-payout">{this.state.userInfo.hasOwnProperty('current_balance') ? this.state.userInfo.current_balance : ''}</NavLink></span>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                {
                                    this.state.userInfo.affiliate_url !== '' ?
                                        <Col md={4}>
                                            <div className="dashboar_col position-relative">
                                                <h3 className="font_16 text_black fw_el_bold mb_7 text-uppercase">Referral Link</h3>
                                                {/* <p className="para font_14 line_height_22 text_black mb_42 fw_ar_reg">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod </p> */}
                                                <input type="text" className='mb_15 font_16 text_black fw_ar_reg' value={this.state.userInfo.hasOwnProperty('affiliate_url') ? this.state.userInfo.affiliate_url : ''} id="affiliateURL" readOnly />
                                                <button className="w-100 d-flex align-items-center mb_17 justify-content-center copied_btn" onClick={this.copyAffiliateURL}>
                                                    <img src={CopyIcon} alt="copy_icon" />
                                                    <span className="pl_5 font_12 text_black fw_ar_bold ">Copy referral link</span>
                                                </button>
                                                <span id="copyMsg" className='font_14 fw_ar_reg' style={{ color: 'green', display: 'none' }}>Copied!</span>
                                                {/* <span className="text-center d-block mb_14 font_12 let_spa_2 text_black">or Share via</span>
                                                <div className="d-flex justify-content-center">
                                                    <NavLink to={'#'}><img src={InstagramIcon} alt="instagrram icon" className="mr_8" /></NavLink>
                                                    <NavLink to={'#'}><img src={FacebookIcon} alt="facebook icon" /></NavLink>
                                                </div> */}
                                            </div>
                                        </Col>
                                        : ''
                                }

                            </Row>
                            <div className="clearfix"></div>
                            <div className="pull-left order_aside">
                                {/* <li>Today's Order: <NavLink to="/my-account/view-order">{this.state.userInfo.hasOwnProperty('todays_order') ? this.state.userInfo.todays_order : ''}</NavLink></li> */}
                                {/* <li>Lifetime Order: <NavLink to="/my-account/view-order">{this.state.userInfo.hasOwnProperty('lifetime_order') ? this.state.userInfo.lifetime_order : ''}</NavLink></li> */}
                                {/* <li>Lifetime Success Order: <NavLink to="/my-account/view-order">{this.state.userInfo.hasOwnProperty('lifetime_success_order') ? this.state.userInfo.lifetime_success_order : ''}</NavLink></li> */}



                            </div>
                        </Fragment>
                }

            </Fragment>
        );
    }
}

export default MyAccount;

// function mapStateToProps(state) {
//     return {
//         auth: state.auth
//     };
// }

// export default connect(mapStateToProps,null)(MyAccount);
